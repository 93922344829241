@font-face {
  font-display: swap;
  font-family: "Anthology-Outline";
  src: local("Anthology-Outline"),
    url(./fonts/Anthology-Font/Anthology-Outline.otf) format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Anthology-Outline-Slanted";
  src: local("Anthology-Outline-Slanted"),
    url(./fonts/Anthology-Font/Anthology-Outline-Slanted.otf) format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Anthology-Sans-Shadow";
  src: local("Anthology-Sans-Shadow"),
    url(./fonts/Anthology-Font/Anthology-Sans-Shadow.otf) format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Anthology-Sans-Slanted";
  src: local("Anthology-Sans-Slanted"),
    url(./fonts/Anthology-Font/Anthology-Sans-Slanted.otf) format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Anthology-Sans";
  src: local("Anthology-Sans"),
    url(./fonts/Anthology-Font/Anthology-Sans.otf) format("opentype");
}

body {
  margin: 0;
  font-family: "Anthology-Sans" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url("/images/BODY_Pattern.svg");
}

.icon img {
  height: 100%;
  object-fit: cover;
}

#rasa-chat-widget-container {
  bottom: -100px;
  transition-duration: 0.8s;
  transition-property: bottom;
  font-family: "Noto Sans";
}

#rasa-chat-widget-container.loaded {
  bottom: 20px;
  display: none;
}

#rasa-chat-widget-container button {
  background-color: rgb(231, 208, 71);
}

@font-face {
  font-family: "sofia-pro";
  src: url("./fonts/typekit/sofia.woff2") format("woff2"),
    url("./fonts/typekit/sofia.woff") format("woff"),
    url("./fonts/typekit/sofia.otf") format("opentype");
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  font-display: swap;
}

.tk-sofia-pro {
  font-family: "sofia-pro", sans-serif;
}

/* panolens start */
#container {
  height: 450px;
}

#controlButton {
  top: 1rem;
}

#modeButton {
  top: 4rem;
}

body {
  min-height: 100vh;
}

.modal-open {
  overflow: hidden;
}


#infospot {
  font-family: "sofia-pro";
  font-size: 0.75rem;
  max-width: 500px;
  max-height: 500px;
  background: #fff;
  color: #000;
  border-radius: 3px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 20px;
}
/* panolens end */

video {
  width: 100%;
}

#container {
  border-radius: 25px;
}

#container canvas {
  border-radius: 25px;
}

#container > :nth-child(3) {
  background: rgba(0, 0, 0, 0.5) !important;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}


/* Global styling to avoid hashing for pretix widget*/
.pretix-widget-event-calendar-event {
  font-family: "sofia-pro";
}


.pretix-widget-event-calendar-event-name {
  font-size: 1.2rem;
  line-height: 1;
  word-wrap: normal;
}

.pretix-widget-event-calendar-event-date {
  font-size: 1rem;
}

.pretix-widget-event-calendar-event-availability{
  font-size: 0.8rem;
}

.pretix-widget-attribution {
  display: none;
}
.pretix-widget-event-calendar-table {
  margin-top: 1rem;
}

.pretix-widget-event-calendar-table td{
    border: 1px solid #69687140;
}
.pretix-widget-event-calendar{
  padding-bottom: 10px;
}

/* Global styling fo align slides to the left for slick-slider*/
.slick-track {
  float:left;
}